import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image/withIEPolyfill';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import './product.styl';
import Share from '../Share';
import LocalizedLink from '../LocalizedLink';
import { altify } from '../../../utils/strings';
import getCopyright from '../../../constants/copyrights';

const CustomButton = withStyles(() => ({
    root: {
        position: 'absolute',
        borderRadius: 0,
        height: '100%',
        '&:before': {
            display: 'none',
        },
    },
}))(IconButton);

const PrevControl = ({ className, onClick }) => (
    <CustomButton
        size='medium'
        color='primary'
        onClick={onClick}
        className={className}
        aria-label='Prev'
        // style={style}
    >
        <KeyboardArrowLeft />
    </CustomButton>
);

const NextControl = ({ className, onClick }) => (
    <CustomButton
        size='medium'
        color='primary'
        onClick={onClick}
        className={className}
        aria-label='Next'
        // style={style}
    >
        <KeyboardArrowRight />
    </CustomButton>
);

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        const { location } = props;
        this.state = {
            nav1: null,
            nav2: null,
            prevUrl: location.state,
            historyLength: -1,
        };
    }

    componentDidMount() {
        this.setRefs();
    }

    setRefs() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    setUrl(id) {
        const { location } = this.props;
        const baseUrl = location.pathname.split('/')[1];
        this.setState({
            historyLength: this.state.historyLength - 1,
        });
        window.history.pushState('test', baseUrl, `/${baseUrl}/${id}`);
    }

    goToPrevPage(e) {
        const { prevUrl, historyLength } = this.state;
        if (prevUrl !== null && prevUrl.prevPath) {
            e.preventDefault();
            window.history.go(historyLength);
        }
    }

    render() {
        const {
            product,
            collection,
            pageInfos,
            location,
            collectionName,
            title,
            description,
        } = this.props;
        const { type } = pageInfos;
        const itemMax = collection.length;
        const orderedCollection = collection.reduce(
            (acc, curr) => {
                if (curr.node.id !== product.id) acc.push(curr.node);
                return acc;
            },
            [product],
        );

        return (
            <div className='product-detail'>
                <div className='product-title'>
                    <LocalizedLink
                        className='back-button'
                        to={location.pathname.split('/')[1]}
                        onClick={this.goToPrevPage.bind(this)}
                    >
                        <IconButton color='inherit'>
                            <KeyboardArrowLeft />
                        </IconButton>
                    </LocalizedLink>
                    {title}
                </div>
                <div className='product-description'>
                    <FormattedMessage id={description} />
                </div>
                <div className='sliders-bloc'>
                    <Slider
                        // {...settings}
                        className='slider-main'
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        afterChange={index =>
                            this.setUrl(orderedCollection[index].id)
                        }
                        arrows={false}
                        // slidesToScroll={1}
                    >
                        {orderedCollection.map((item, index) => (
                            <div key={item.id} className='slide'>
                                <Img
                                    fluid={item.childImageSharp.fluid}
                                    style={{
                                        height: 'auto',
                                        maxHeight: '600px',
                                    }}
                                    imgStyle={{
                                        // objectFit: "contain",
                                        width: '100%',
                                        backgroundSize: 'contain',
                                        maxHeight: '600px',
                                    }}
                                    objectFit='contain'
                                    objectPosition='50% 50%'
                                    alt={altify(
                                        `${collectionName}-${item.name}`,
                                    )}
                                />
                                <div
                                    className='copyrights'
                                    dangerouslySetInnerHTML={{
                                        __html: getCopyright(
                                            type,
                                            collectionName,
                                            item.name,
                                        ),
                                    }}
                                />
                            </div>
                        ))}
                    </Slider>
                    <Share
                        url={location.pathname}
                        description={description}
                        media={product.childImageSharp.original.src}
                    />
                    {itemMax > 1 ? (
                        <>
                            <div className='thumbnail-header'>
                                <FormattedMessage
                                    id='products.other'
                                    values={{ collection: collectionName }}
                                />
                            </div>
                            <Slider
                                asNavFor={this.state.nav1}
                                ref={slider => (this.slider2 = slider)}
                                slidesToShow={itemMax >= 5 ? 5 : itemMax}
                                swipeToSlide={true}
                                focusOnSelect={true}
                                className={'slider-thumbnails'}
                                nextArrow={<NextControl />}
                                prevArrow={<PrevControl />}
                                centerMode
                            >
                                {orderedCollection.map((item, index) => (
                                    <div
                                        key={item.id}
                                        className='slide-thumbnail'
                                    >
                                        <Img
                                            fluid={item.childImageSharp.fluid}
                                            style={{
                                                objectFit: 'contain',
                                                maxHeight: '70px',
                                                height: '70px',
                                                width: '100%',
                                            }}
                                            imgStyle={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                backgroundSize: 'contain',
                                            }}
                                            objectFit='cover'
                                            objectPosition='50% 50%'
                                            alt={altify(
                                                `${collectionName}-${item.name}`,
                                            )}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }
}
ProductDetail.propTypes = {
    product: PropTypes.object.isRequired,
    pageInfos: PropTypes.object.isRequired,
    collection: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    collectionName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default ProductDetail;
