import {
    SOURCE_CARDS,
    SOURCE_WRAPS,
    SOURCE_ITEMS,
    PAGE_CARTERIE,
    PAGE_EMBALLAGE,
    PAGE_OBJET_CADEAU,
} from '../constants/router';
import {
    PRODUCT_TYPE_CARD,
    PRODUCT_TYPE_WRAP,
    PRODUCT_TYPE_ITEM,
} from '../constants/products';

export const getPageFromSourceInstance = sourceInstance => {
    const pageInfos = {};
    switch (sourceInstance) {
        case SOURCE_CARDS:
            pageInfos.path = PAGE_CARTERIE;
            pageInfos.type = PRODUCT_TYPE_CARD;
            break;
        case SOURCE_WRAPS:
            pageInfos.path = PAGE_EMBALLAGE;
            pageInfos.type = PRODUCT_TYPE_WRAP;
            break;
        case SOURCE_ITEMS:
            pageInfos.path = PAGE_OBJET_CADEAU;
            pageInfos.type = PRODUCT_TYPE_ITEM;
            break;
        default:
            pageInfos.path = PAGE_CARTERIE;
            pageInfos.type = PRODUCT_TYPE_ITEM;
            break;
    }
    return pageInfos;
};
