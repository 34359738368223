const other = '©Droits réservés';

const copyrights = {
    card: {
        // Fashion
        'serie-d-artiste':
            '<a href="https://www.amy-sia.com/" target="_blank" rel="noreferrer noopener">©Amy Sia</a>',
        'very-chic': name =>
            name === 'IMG_8986'
                ? '<a href="https://annscottdesign.com/" target="_blank" rel="noreferrer noopener">© Ann Scott Design</a>'
                : other,
        'pop-cart': name => {
            let result;
            switch (name) {
                case 'IMG_0691N':
                case 'IMG_9135':
                    result = '©Juan de Lascurain-Gossler';
                    break;
                case 'IMG_9145':
                    result = '©Kate Mawdsley';
                    break;
                default:
                    result = other;
            }
            return result;
        },
        // Generaliste
        'grand-comme-ca': name =>
            name === 'grand-comme-ca1' ? '©Howard Robinson' : other,
        'mise-en-scene': name =>
            name === 'IMG_9233' ? '©Howard Robinson' : other,
        // Humour
        'cha-cha-cha':
            'Cats © Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway.com</a>',
        'echec-et-mat':
            'Echec et Mat © Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway.com</a>',
        'farce-attrape':
            'Farce et attrape © Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway.com</a>',
        'la-x-et-la-maniere':
            'Designs and Text © Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway Ltd</a>',
        'geante-maxi-geante_showlapin-chachacha':
            '© Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway.com</a>',
        licorne:
            'Unicorn © Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway Ltd</a>',
        'show-lapin':
            'Nic © Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway.com</a>',
        soundcart: name => {
            let result;
            switch (name) {
                case 'IMG_9488':
                    result = '© Howard Robinson';
                    break;
                case 'IMG_9490':
                    result =
                        '© Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway.com</a>';
                    break;
                case 'COLLEC99956_1':
                    result =
                        '© Howard Robinson et Cats © Alexander Holzach, licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway.com</a>';
                    break;
                default:
                    result = other;
            }
            return result;
        },
        voutch:
            '<a href="http://voutch.com/" target="_blank" rel="noreferrer noopener">©Voutch</a>',
        // Tendresse
        aerial:
            '©Nicola Gregory - Courtesy of <a href="http://www.image-source.co.uk" target="_blank" rel="noreferrer noopener">www.image-source.co.uk</a>',
        'jardin-anglais':
            '<a href="https://www.harrietmellor.com/" target="_blank" rel="noreferrer noopener">©Harriet Mellor</a>',
        paradisio:
            '<a href="http://gabbymalpas.com/" target="_blank" rel="noreferrer noopener">©Gabby Malpas</a>',
        // Unicef
        unicef:
            '<a href="https://www.unicef.fr/" target="_blank" rel="noreferrer noopener">©Unicef</a>',
        // Enfant
        dreamworks:
            '<a href="https://www.universalstudioshollywood.com/" target="_blank" rel="noreferrer noopener">©2019 Universal Studios</a>. All Rights Reserved',
        'le-petit-monde-de-kali':
            '<a href="https://www.kalistileman.co.uk/" target="_blank" rel="noreferrer noopener">©Kali Stileman</a>',
    },
    wrap: {
        aquacolor: name => {
            let result;
            switch (name) {
                case 'IMG_8075_1M':
                    result = other;
                    break;
                case 'IMG_3446-N':
                    result =
                        '© Nicola Gregory – Courtesy of <a href="http://www.image-source.co.uk" target="_blank" rel="noreferrer noopener">www.image-source.co.uk</a>';
                    break;
                default:
                    result =
                        '<a href="https://www.amy-sia.com/" target="_blank" rel="noreferrer noopener">©Amy Sia</a>';
            }
            return result;
        },
        celeste: name =>
            name === 'IMG_5200M'
                ? '© Nicola Gregory – Courtesy of <a href="http://www.image-source.co.uk" target="_blank" rel="noreferrer noopener">www.image-source.co.uk</a>'
                : other,
        'happy-kids': name => {
            let result;
            switch (name) {
                case 'IMG_0622':
                case 'IMG_3361':
                case 'IMG_3363':
                    result =
                        '© Alexander Holzach – Licensed by <a href="http://licensegateway.com/" target="_blank" rel="noreferrer noopener">Licensegateway.com</a>';
                    break;
                case 'IMG_9631':
                    result = '© Kate Mawdsley';
                    break;
                case 'IMG_3015':
                    result = '© Kate Mawdsley et © Alexander Holzach';
                    break;
                default:
                    result = other;
            }
            return result;
        },
        paradisio: name => {
            let result;
            switch (name) {
                case 'IMG_8051_1M':
                case 'IMG_8066_1M':
                case 'IMG_8088_1M':
                case 'IMG_9625':
                    result =
                        '<a href="http://gabbymalpas.com/" target="_blank" rel="noreferrer noopener">©Gabby Malpas</a>';
                    break;
                case 'IMG_3328-N':
                    result = '© Valérie Dumas';
                    break;
                default:
                    result = other;
            }
            return result;
        },
        pretty: name => (name === 'IMG_3084' ? '© déco et des mots' : other),
        spring: name => (name === 'IMG_8017M' ? '© Emma Schonnenberg' : other),
    },
};

const getCopyright = (type, collectionName, fileName) => {
    if (
        copyrights[type] === undefined ||
        copyrights[type][collectionName] === undefined
    ) {
        return other;
    } else {
        if (typeof copyrights[type][collectionName] === 'function') {
            return copyrights[type][collectionName](fileName);
        }
        return copyrights[type][collectionName];
    }
};

export default getCopyright;
