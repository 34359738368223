/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MainLayout from '../layouts/MainLayout';
import Parallax from '../components/Parallax';
import ProductDetail from '../components/ProductDetail';
import { getPageFromSourceInstance } from '../../utils/page';
import { collections } from '../../constants/products';
import { getCollectionName } from '../../utils/products';
import SEO from '../components/SEO';

const ProductTemplate = ({
    pageContext: {
        locale,
        id,
        originalPath,
        sourceInstanceName,
        relativeDirectory,
    },
    location,
    data,
    children,
}) => {
    const { product, collection, coverImage } = data;
    const pageInfos = getPageFromSourceInstance(product.sourceInstanceName);
    const collectionName = getCollectionName(product.relativeDirectory);
    const title = collections[pageInfos.type][collectionName];
    const description = `products.${pageInfos.type}.${collectionName}`;

    return (
        <MainLayout
            locale={locale}
            location={location}
            originalPath={originalPath}
            slugId={id}
        >
            <SEO
                title='products.collection'
                collection={title}
                description={description}
                originalPath={originalPath}
                location={location}
                metaIcon={data.seoImage.childImageSharp.resize.src}
                isProduct
                slug={`${id}/`}
            />
            <div className='page-bg'>
                <Parallax
                    img={coverImage.childImageSharp.fluid}
                    alt='image-carterie-groupe-editor'
                    overlay='light-bottom-left'
                >
                    <div />
                </Parallax>
                <div className='page-content product'>
                    <ProductDetail
                        product={product}
                        pageInfos={pageInfos}
                        collection={collection.edges}
                        collectionName={collectionName}
                        location={location}
                        originalPath={originalPath}
                        title={title}
                        description={description}
                    />
                </div>
            </div>
        </MainLayout>
    );
};

ProductTemplate.propTypes = {
    pageContext: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default ProductTemplate;

export const query = graphql`
    query(
        $id: String!
        $sourceInstanceName: String!
        $relativeDirectory: String!
    ) {
        coverImage: file(
            sourceInstanceName: { eq: "parallax" }
            name: { eq: $sourceInstanceName }
        ) {
            ...BgImages
        }
        seoImage: file(id: { eq: $id }) {
            ...SeoImages
        }
        product: file(id: { eq: $id }) {
            id
            name
            relativeDirectory
            sourceInstanceName
            childImageSharp {
                fluid(
                    maxWidth: 800
                    srcSetBreakpoints: [
                        200
                        300
                        400
                        600
                        800
                        900
                        1200
                        1600
                        2400
                    ]
                    quality: 70
                    traceSVG: { background: "#f6f7eb", color: "#919eab" }
                ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    presentationWidth
                }
                original {
                    src
                }
            }
        }
        collection: allFile(
            filter: {
                sourceInstanceName: { eq: $sourceInstanceName }
                relativeDirectory: { eq: $relativeDirectory }
            }
        ) {
            ...MasonryImages
        }
    }
`;
